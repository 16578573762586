.icon {
  transform: rotate(210deg);
  transform-origin: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.uk-button-primary {
  background: rgb(3, 102, 180);
  background: linear-gradient(
    142deg,
    rgba(3, 102, 180, 1) 0%,
    rgba(2, 131, 231) 100%
  );
  text-transform: uppercase;
  border-radius: 2rem;
  color: #fff;
}

.uk-button-secondary {
  background: rgb(3, 102, 180);
  background: linear-gradient(
    142deg,
    rgba(119, 119, 119, 1) 0%,
    rgba(181, 181, 181, 1) 100%
  );
  text-transform: uppercase;
  border-radius: 2rem;
  color: #fff;
}

.uk-button {
  border-radius: 2rem;
}

.nav-logo {
  width: 175px;
}

.nav-bg {
  border-top: solid 0.55rem #ededed;
  background: rgb(255, 255, 255);
}

.foot-bg {
  background: rgb(50, 66, 82);
}

.feature-1 {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("./images/feat-2.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("./images/feat-2.jpg");
  background-position: center;
  background-size: cover;
  background-color: #1e87f0;
}

.feature-2 {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("./images/feat-1.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("./images/feat-1.jpg");
  background-position: center;
  background-size: cover;
  background-color: #666;
}

.feature-3 {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("./images/feat-3.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("./images/feat-3.jpg");
  background-position: center;
  background-size: cover;
  background-color: #666;
}

/* Scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(2, 131, 231);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ededed;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(2, 131, 231);
  border-radius: 5px;
  border: 0;
}

.box-shadow-large {
  box-shadow: -40px 40px 160px 0 rgb(0 0 0 / 8%),
    -8px 8px 15px 0 rgb(120 120 120 / 4%), 3px 3px 30px 0 rgb(0 0 0 / 4%);
}

.profile-nav {
  width: 45px;
  height: 45px;
  -webkit-transition: width 200ms, height 200ms; /* Safari */
  transition: width 200ms, height 200ms;
  box-shadow: -40px 40px 160px 0 rgb(0 0 0 / 28%),
    -8px 8px 15px 0 rgb(120 120 120 / 25%), 3px 3px 30px 0 rgb(0 0 0 / 25%);
  border: 2px solid #1e87f0;
}

.profile-nav:hover {
  width: 47px;
  height: 47px;
  box-shadow: -40px 40px 160px 0 rgb(0 0 0 / 32%),
    -8px 8px 15px 0 rgb(120 120 120 / 32%), 3px 3px 30px 0 rgb(0 0 0 / 32%);
}

.uk-input {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
  border: 0;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
  border: 0;
}

.uk-textarea {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
  font-size: 16px;
  border: 0;
}

.uk-card-body {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
}

.sky {
  color: rgba(3, 102, 180, 1);
  font-weight: bold;
}

.login-section {
  min-height: 100vh;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/hero.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/hero.jpg");
  background-size: cover;
  /* background: url("https://live.staticflickr.com/65535/50685960898_b00acf251b_k.jpg"); */
  background-color: rgb(50, 66, 82);
}

.uk-input-large,
.uk-select-large {
  height: 54px !important;
}

.crew-logo {
  width: 50px;
  margin-right: 10px;
}

.reset-password-link {
  color: #555;
}

.uk-table-hover tbody tr:hover,
.uk-table-hover > tr:hover {
  background: rgba(0, 0, 0, 0.02);
}

.flights-stats .uk-text-bold {
  font-size: 48px;
}

.uk-nav > li > a.uk-active {
  color: rgb(50, 66, 82) !important;
}

.uk-navbar-nav > li > a.uk-active {
  color: rgb(50, 66, 82) !important;
}

.uk-navbar-nav a {
  color: rgb(50, 66, 82) !important;
  font-weight: 500;
}

.nav-ribbon {
  background: linear-gradient(
    142deg,
    rgba(3, 102, 180, 1) 30%,
    rgba(2, 131, 231) 100%
  );
  color: #fff;
  padding: 0.25rem;
}

.nav-ribbon .uk-container {
  display: flex;
}

.nav-ribbon-info {
  justify-self: flex-end;
  text-align: right;
  letter-spacing: 0.04rem;
}

.nav-ribbon-info span {
  margin-left: 0.5rem;
}

.searchWrapper {
  height: inherit;
  overflow-y: scroll;
  border: none !important;
}
/* 
html {
  filter: invert(1) hue-rotate(180deg);
}

html img{
  filter: invert(1) hue-rotate(180deg);
} */

/* Dark Mode !!! */

/* html[theme='dark-mode'] {
  filter: invert(90%) contrast(1.1) saturate(1.05) hue-rotate(180deg);
}

html[theme='dark-mode'] .uk-button-primary {
  filter: invert(90%) contrast(1.1) saturate(1.05) hue-rotate(180deg);
}

html[theme='dark-mode'] img,
html[theme='dark-mode'] .bg-image,
html[theme='dark-mode'] .feature-1,
html[theme='dark-mode'] .feature-2,
html[theme='dark-mode'] .section-intro {
  filter: invert(1) hue-rotate(180deg);
}

html[theme='dark-mode'] #stats-total-flights {
  filter: invert(1) hue-rotate(180deg);
  color: white;
}


html[theme='dark-mode'] .uk-logo,
html[theme='dark-mode'] .profile-nav,
html[theme='dark-mode'] .login-section,
html[theme='dark-mode'] .section-intro .uk-button-primary
html[theme='dark-mode'] .uk-navbar-dropdown,
html[theme='dark-mode'] .foot-bg {
  filter: invert(1) hue-rotate(180deg);
} */
